<template>
  <div id="CustomerAOYA">
    <!-- CustomerAOYA头部内容 -->
    <div class="CustomerAOYAHeader">
      <!-- 头部组件 -->
      <Header />
    </div>
    <!-- CustomerAOYA中间内容 -->
    <div class="CustomerAOYACenter">
      <!-- 客户介绍 -->
      <div class="contentOne">
        <div class="titleOne">客户介绍</div>
        <div class="conInfo">
          <img src="~assets/img/CustomerAOYA/AOYA@2x.png" />
          <div class="infoOne">
            浙江澳亚织造股份有限公司成立于2013年，是专业从事织带设计开发、织造、销售和服务为一体化的企业，产品远销欧美、亚太地区及国内一线市场。如今澳亚产品
            已经覆盖形成以体育用品、医疗保健、工业工程、服装辅料和老年儿童为主的多领
            域和多系列产品。<br />
            澳亚目前拥有多种国外先进的针织、机织及提花织造设备，并配备完善的检测、后
            整理、滴胶、包纱、压花等辅助设备。至今已有60万米的日生产能力，是中国最大
            的松紧带制造商之一。
          </div>
        </div>
      </div>
      <!-- 项目价值 -->
      <div class="contentTwo">
        <div class="titleTwo">项目价值</div>
        <div class="titleT">
          2020年8月，澳亚织造联合东阳优云科技有限公司（浙江三象数据有限公司子公司）打造东阳市首个5G+工业互联网试点项目，通过现有织造设备传感改造和5G网络建设，构建覆盖主要织造设备的物联网监控系统，实现了设备状态、作业过程、生产量的实时采集和统计,实现覆盖排产、派工、生产执行、检验、设备监控等全流程的数字化管理。
        </div>

        <ul class="conBot">
          <li class="con1" v-for="(item, index) in conBots" :key="index">
            <div class="conLeft">
              <img :src="item.imgs" alt="" />
              <div class="leftPro">
                <div class="leftNum">{{ item.num1 }}</div>
                <div class="leftText">{{ item.text1 }}</div>
              </div>
            </div>
            <div class="conLeft">
              <img :src="item.imgs" alt="" />
              <div class="leftPro">
                <div class="leftNum">{{ item.num2 }}</div>
                <div class="leftText">{{ item.text2 }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 项目概况 -->
      <div class="contentThree">
        <div class="titleThree">项目概况</div>
        <ul class="imgContent">
          <li v-for="(item, index) in imgContents" :key="index">
            <img :src="item.Imgs" />
            <div class="imgBottom">{{ item.textPro }}</div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 底部内容 -->
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/CustomerAOYA/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
export default {
  name: "CustomerAOYA",
  data() {
    return {
      imgContents: [
        {
          Imgs: require("assets/img/CustomerAOYA/驾驶舱详情@2x.png"),
          textPro: "驾驶舱详情"
        },
        {
          Imgs: require("assets/img/CustomerAOYA/设备监控@2x.png"),
          textPro: "设备监控"
        },
        {
          Imgs: require("assets/img/CustomerAOYA/工单监控@2x.png"),
          textPro: "工单监控"
        },
        {
          Imgs: require("assets/img/CustomerAOYA/领导调研澳亚数字化工厂项目.png"),
          textPro: "2021年8月31日 金华市委书记陈龙调研澳亚数字化工厂项目"
        },
        {
          Imgs: require("assets/img/CustomerAOYA/员工在车间使用系统@2x.png"),
          textPro: "员工在车间使用系统"
        },
        {
          Imgs: require("assets/img/CustomerAOYA/新闻报道@2x.png"),
          textPro: "新闻报道"
        }
      ],
      conBots: [
        {
          imgs: require("assets/img/CustomerAOYA/icon-提升@2x.png"),
          num1: "30%",
          text1: "挡车工生产效率提升",
          num2: "30%",
          text2: "设备利用率提升"
        },
        {
          imgs: require("assets/img/CustomerAOYA/icon-缩短@2x.png"),
          num1: "10%",
          text1: "生产周期缩短",
          num2: "20%",
          text2: "减少原材料浪费"
        }
      ]
    };
  },
  components: {
    Header,
    Footer
  }
};
</script>
<style lang="less" scope>
#CustomerAOYA {
   width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // CustomerAOYA头部样式
  .CustomerAOYAHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerAOYA/banner@2x.png") no-repeat;
     background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }
  // CustomerAOYA中间内容样式
  .CustomerAOYACenter {
    width: 1220px;
    margin: 80px auto 101px;
    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .conInfo {
        margin-top: 30px;
        display: flex;
        img {
          width: 560px;
          height: 343px;
        }
        .infoOne {
          width: 600px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 93px 0 0 40px;
        }
      }
    }
    // 项目价值
    .contentTwo {
      margin-top: 60px;
      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }
      .titleT {
        width: 1200px;
        height: 72px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
      .conBot {
        margin-top: 30px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
          width: 584px;
          height: 151px;
          overflow: hidden;
          display: flex;
          background: #ecf6ff;
          .conLeft {
            display: flex;
            margin-left: 60px;
            margin-top: 42px;
            img {
              width: 70px;
              height: 70px;
            }
            .leftPro {
              margin-left: 14px;
              .leftNum {
                width: 78px;
                height: 49px;
                font-size: 40px;
                font-family: DIN;
                font-weight: bold;
                color: #2c76e3;
              }
              .leftText {
                width: 127px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 28px;
                color: rgba(51, 51, 51, 0.85);
              }
            }
          }
        }
      }
    }
    // 项目概况
    .contentThree {
      margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;
        padding: 0;
        li {
          margin-bottom: 30px;
          background-color: #fff;
          &:nth-child(1){
              width: 335px;
              height: 346px;
              img{
                  width: 335px;
                  height: 300px;
              }
          }
          &:nth-child(2),&:nth-child(3){
              width: 400px;
              height: 346px;
              img{
                  width: 400px;
                  height: 300px;
              }
          }
          &:nth-child(4){
              width: 468px;
              height: 372px;
              img{
                  width: 468px;
                  height: 327px;
              }
          }
          &:nth-child(5){
              width: 435px;
              height: 372px;
              img{
                  width: 435px;
                  height: 326px;
              }
          }
          &:nth-child(6){
              width: 227px;
              height: 372px;
              img{
                  width: 227px;
                  height: 325px;
              }
          }

          
         .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
